/**
 * Testimonials
 **/

.testimonials{
	padding-top: 2rem;
	padding-bottom: 2rem;

	h2{
		color: $white;
		margin-bottom: 1.5rem;
	}
}

.testimonial{

	p{
		font-family: $secondary-font;
		font-size: 15px;

		span{
			color: $white;
		}
	}

	&__content{
		@include media-breakpoint-down(md){
			margin-top: 1rem;
			margin-bottom: 2rem;
		}
	}
}


/**
 * Portfolio
 **/ 

.portfolio{
	@extend .block-p;
	
	h2{
		color: $third-color-darker;
	}

	h2,
	ul{
		float: left;
	}

	ul{	
		font-family: $primary-font;
		padding-top: 9px;

		li{
			display: inline;
			list-style-type: none;
			margin-left: 22px;
			
			a{
				color: $black;
				text-transform: uppercase;
				font-size: 15px;
				position: relative;
				transition: all .3s ease-in-out;

				&:after{
					content: '';
					position: absolute;
					height: 2px;
					width: 0%;
					background-color: $white;
					left: 0;
					bottom: -2px;

					transition: all .3s ease-in-out;
				}

				&:hover{
					text-decoration: none;

					&:after{
						width: 100%;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(sm){
		ul{
			padding-left: 0;

			li{
				display: block;
				margin-left: 0;
				margin-bottom: 10px;
			}

		}
	}

	&__navigation{
		margin-bottom: 1.5rem;
	}

	&__item{
		margin-bottom: 2rem;
		transition: all .3s ease-in-out;
		display: none;
		
		&:hover{
			.portfolio-item__content{
				border: 1px solid $white;
				transform: scale(1);
				opacity: 1;
				cursor: pointer;
			}

			.portfolio-item__overlay{
				opacity: 0.5;
			}
		}

		&.reveal{
			display: inherit;
		}

	}
}

.portfolio-item{
	&__image{
		position: relative;
	}

	&__content{
	    position: absolute;
	    top: 35%;
	    left: 10%;
	    width: 80%;
	    text-align: center;
	    border: 1px solid rgba($white, 0);
	    padding: 15px;
		transition: all .5s ease-in-out;
		transform: scale(.9);
		opacity: 0;

		h3,
		span{
			color: $white;
			font-family: $primary-font;
		}	    
	}

	&__overlay{
		position: absolute;
    	top: 0;
    	left: 0;
    	height: 100%;
    	width: 100%;
    	background-color: $blue;
    	opacity: 0;
    	transition: all .3s ease-in-out;	
	}

	&__quote{
		.inner{
			background-color: rgba(#ce836f, 0.5);
			height: calc(100% - 2rem);
			text-align: center;
			padding: 25px;
			padding-top: 4rem;
			font-family: $secondary-font;
			font-size: 20px;			
		}	
	}

	&__cta{
		.inner{
			background-color: #ce836f;
			height: calc(100% - 2rem);
		}

		.inner-content{
			text-align: center;
		    border: 1px solid $white;
		    padding: 25px;
		    position: absolute;
		    top: 25%;
		    left: 10%;
		    width: 80%;

			h3,
			span{
				color: $white;
				font-family: $primary-font;
			}
		}
		
		@include media-breakpoint-down(sm){
			min-height: 400px !important;
		}
	}
}


/**
 * Realise Project
 **/

.realise-project{
	@extend .block-p;

	h2{
		@extend .blue;
	}
}


/**
 * What you need
 **/

.what-you-need{
	@extend .block-p;

	h2{
		color: $third-color-darker;		
	}

	h3{
		color: $third-color-darkest;

		span{
			color: $third-color-darker;
		}
	}

	p{
		border-left: 2px solid $third-color-darker;
		padding-left: 15px;
	}

	&__content{
		margin-top: 2rem;
	}
}


/**
 * Projects
 **/

.projects{
	@extend .block-p;

	h2{
		color: $third-color-darkest;
		margin-bottom: 2rem;
	}

	h3{
		color: $third-color-darker;

		span{
			color: $third-color-darkest;
		}

		@include media-breakpoint-down(sm){
			margin-top: 3rem;
		}
	}

	p{
		border-left: 2px solid $white;
		padding-left: 15px;
	}

	&__content{
		margin-bottom: 2rem;
	}

	&__image{
		position: relative;

		@include media-breakpoint-down(sm){
			margin-bottom: 1.5rem;
		}		
	}

	&__cta{
		position: absolute;
    	left: 0;
    	top: 30%;
    	width: 100%;

    	p{
    		width: 100%;
    		text-align: center;    		
    		padding-left: 0;
    		border-left: none;
    	}
	}	
}


/**
 * Team members - contact
 **/

.design-team-intro{
	@extend .block-p;

	h2{
		color: $third-color-darker;
	}

	img{
		margin-bottom: -3rem;
	}
}

.design-team-contact{
	@extend .block-p;

	.team-member{
		transition: all .3s ease-in-out;

		&:hover{
			transform: scale(1.02);

			h4{
				&:after{
					transform: scaleX(1.25);
				}
			}
		}

		@include media-breakpoint-down(sm){
			margin-bottom: 2rem;
		}
	}
	
	.team-member-inner{
		background-color: $white;
	}

	.team-member-content{
		padding: 15px;
	}

	h3{
		color: $blue;
	}

	h4{
		color: $blue;
		font-family: $secondary-font;
		font-size: 22px;
		text-align: center;
		position: relative;
		margin-bottom: 3rem;
		margin-top: 1rem;

		span{
			color: #565657;
			font-family: $primary-font;
			display: block;
			font-size: 14px;
			margin-top: 0.75rem;
		}

		&:after{
			position: absolute;
			content: '';
			width: 90px;
			height: 3px;
			background-color: $blue;
			left: calc(50% - 45px);
			bottom: -25px;
			transition: all .3s ease-in-out;

		}
	}

	.before-contact-cta{
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}


/**
 * Quote
 **/

	.quote{
		border: 1px solid $white;
		color: $white;
		font-family: $secondary-font;
		font-size: 20px;
		text-align: center;
		padding: 25px;
		margin-bottom: 4rem;

		div{
			text-shadow: -1px -1px 10px rgba(#000000, .75);			
		}

		span{
			color: $third-color-darker;
			font-family: $primary-font;
			font-size: 12px;
			font-weight: 700;
		}

		@include media-breakpoint-down(sm){
    		width: 80%;
    		margin-left: 10%;			
		}
	}


/**
 * Contact form
 **/

 .contact-form{
 	h3{
 		color: $white;
 		margin-top: 2rem;
 		margin-bottom: 1.25rem;
 		font-size: 22px;
  	}

 	/* Form fields */
 	label{
 		font-weight: 500;
 		font-family: $tertiary-font;
 	}

 	input[type="text"],
 	input[type="email"]{
 		border: 0;
 		height: 35px;
 		margin-bottom: 1rem;
 		max-width: 600px;
 		width: 100%
 	}

 	input[type="checkbox"]{
	    height: 20px;
	    width: 20px;
	    border: 1px solid $white;
	    border-radius: 0; 		
 	}

 	input[type="submit"]{
 		background-color: transparent;
  		color: $white;
  		border: 1px solid $white;
  		border-radius: 0;
  		padding-top: 5px;
  		padding-bottom: 5px;
  		padding-left: 50px;
  		padding-right: 50px;
  		margin-top: 2rem; 		
 	}

 	span.wpcf7-list-item{
 		margin: 0;
 		display: block;
 	}

 	span.wpcf7-list-item-label{
		position: relative;
    	top: -5px;
    	margin-left: 5px; 		
 	}

 	.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output{
		border-color: transparent;
 	}
 }
