/**
 * Typography
 **/

html{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

 body{
 	color: #565657;
 	font-family: $tertiary-font;
 }

 p{
 	font-size: 15px;
 	line-height: 1.6;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6{
 	font-family: $secondary-font;
 }

 @include media-breakpoint-up(md){
 	h2{
 		font-size: 29px;
 	}
 }

 /**
  * Buttons
  **/

  .btn{
  	font-family: $primary-font;

  	&.btn-primary--transparent{
  		color: $white;
  		border: 1px solid $white;
  		border-radius: 0;
  		padding-top: 5px;
  		padding-bottom: 5px;
  		transition: all .3s ease-in-out;

  		&:hover{
  			border: 1px solid $black;
  		}
  	}
  }

.single{

  h2{
    margin-top: 35px;
    margin-bottom: 15px;
  }

   h3{
    font-size: 1.5em;
    margin-top: 25px;
    margin-top: 10px;
  }

  blockquote{
    padding-top: 25px;
    padding-bottom: 15px;
    padding-left: 25px;
    font-size: 1.66em;
    color: #A28C7E;
    font-style: italic;
    position: relative;
    font-family: "Prata", serif;

    &::before{
      content: '"';
      color: #F1EAE0;
      font-size: 2.5em;
      position: relative;
      right: 20px;
    }
  }

}