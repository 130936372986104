/**
 * Footer
 **/

#footer{
	padding-top: 4rem;
	background-color: $fifth-color;

	ul{
		margin-left: 0;
		padding-left: 0;
	}

	li{
		color: $white;
		list-style-type: none;
		margin-bottom: 5px;
		
		a{
			color: $white;
		}
	}

	h5{
		color: $white;
		margin-bottom: 1rem;
	}


	.social-media{ 
		li{
			display: inline;
		}

		img{
			max-width: 25px;
			margin-right: 15px;
		}
	}
}


/**
 * Widgets
 **/

.menu-hoofdnavigatie-container{
	
}

#footer{
	.menu-portfolio-container{
		li{
			list-style-type: none;
		}

		ul li a{
			color: $black;
			text-transform: uppercase;
		}
	}
}