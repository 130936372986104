/**
 * Custom checkboxes
 **/


.wpcf7 .wpcf7-list-item {
  display: block;
  margin-bottom: 10px;
  width: 31%;
}

.wpcf7 .wpcf7-list-item {
  width: 31%;	
  float: left;
}

.wpcf7-form .wpcf7-radio {
  
}

.wpcf7-form .wpcf7-radio label {
  position: relative;
  cursor: pointer;
}

.wpcf7-form .wpcf7-radio input[type=radio] {
	position: relative;
    opacity: 0;

}

.wpcf7-form .wpcf7-radio input[type=radio] + span {
/*   border: 3px solid red;  */
	position: relative;
}

.wpcf7-form .wpcf7-checkbox input[type="checkbox"] + span{
	position:relative;
}

.wpcf7-form .wpcf7-checkbox input[type=checkbox] + span:before {
	display: block;
	position: absolute;
	content: '';
	height: 18px;
	width: 18px;
	top: 0px;
	border: 1px solid $white;
	left: -25px;
	border-radius: 0;
}

.wpcf7-form .wpcf7-checkbox input[type=checkbox] + span:after {
    display: block;
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    top: 6px;
    left: -24px;
    visibility: hidden;
}

.wpcf7-form .wpcf7-radio input[type=radio] + span:before {
	display: block;
	position: absolute;
	content: '';
	height: 18px;
	width: 18px;
	top: 0px;
	border: 1px solid $primary-color;
	left: -25px;
}

.wpcf7-form .wpcf7-checkbox input[type=checkbox] + span:after {
    display: block;
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    top: 6px;
    left: -24px;
    visibility: hidden;
}

.wpcf7-form .wpcf7-radio input[type=radio]:checked + span:before {
    background: transparent;
/*     border-color: red; */
}

.wpcf7-form .wpcf7-radio input[type=radio]:checked + span:after {
   background: #222;
   visibility: visible;
}





.wpcf7-form .wpcf7-checkbox label {
  position: relative;
  cursor: pointer;
}

.wpcf7-form .wpcf7-checkbox input[type=checkbox] {
	position: relative;
	opacity: 0;
	left: -9px;
	top: -4px;
	z-index: 2000;	
}

.wpcf7-form .wpcf7-checkbox input[type=checkbox] + span {
/*   border: 3px solid red;  */
}

.wpcf7-form .wpcf7-checkbox input[type=checkbox] + span:before {
  display: block;
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  top: 0px;
  border: 1px solid $white;
}

.wpcf7-form .wpcf7-checkbox input[type=checkbox] + span:after {
    display: block;
    position: absolute;
    content: '\2713';
    height: 10px;
    width: 10px;
	top: -3px;
	left: -22px;
    visibility: hidden;
    font-size: 16px;
}

.wpcf7-form .wpcf7-checkbox input[type=checkbox]:checked + span:before {
    background: transparent;
/*     border-color: red; */
}

.wpcf7-form .wpcf7-checkbox input[type=checkbox]:checked + span:after {
/*    background: #222; */
   visibility: visible;
}


.script-checkbox {
  
}

.script-checkbox label {
  
}

.script-checkbox label.label-selected {
  background: red;
}


/**
 * Contact form - Questionnaire
 **/

.content-form{
	h3{
		margin-top: 3rem;
	}

	.wpcf7-list-item-label{
		color: $primary-color;
	}

	.wpcf7-form .wpcf7-checkbox input[type=checkbox] + span:before {
		border: 1px solid $third-color-darker;
	}

	textarea,
	select,
	input{
		border-color: $third-color-darker;
	}

	input{
		border: 1px solid $third-color-darker;
	}

	input[type='text'],
	input[type='email']{
		height: 40px;
		margin-bottom: 25px;
		width: 100%;
		max-width: 600px;
	}

	textarea{
		width: 100%;
	}

	select{
		color: $primary-color;
		padding: 5px;
		padding-right: 20px;
		margin-bottom: 1rem;
	}

	label{
		min-width: 100px;
		font-family: $primary-font;
		font-weight: 500;
	}

	input[type='submit']{
		border: 1px solid $primary-color;
		background-color: transparent;
		padding: 2px 50px;
		color: $primary-color;
		margin-bottom: 6rem;
		margin-top: 3rem;
	}
}

.form-intro{
	h1{
		margin-top: 4rem;
	}

	h1,
	p{
		color: $primary-color;
	}
}

.cd-upload-btn{
	color: $primary-color;
}