/**
 * Mobile only
 **/

@include media-breakpoint-down(md) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: inherit !important;
		}
	}
} 

.navbar-brand{
	height: auto !important;
	margin-bottom: 2rem;
}

@include media-breakpoint-down(md) {

	.navbar-brand img{
		max-width: 200px;
	}

	.navbar-toggler{
		width: 50px;
		height: 50px;
		border-radius: 0px;
		background-color: #F1EAE0;
		margin-top: 10px;
		margin-left: 25px;
		padding: 0 !important;

		.navbar-toggler-icon{
			background-image: url("http://insight-inbe.webhosting.be/wp-content/themes/timstrap-master/dist/images/toggler.svg");
			width: 33px !important;
			height: 33px !important;

		}
	}

}

.navbar-nav > li{
    margin-left: 55px;	

    a.nav-link{
    	text-transform: uppercase;
    	font-size: 14px;
    }
}

.navbar-light .navbar-nav .nav-link{
    color: $black;
    position: relative;
    padding: 0;
	transition: all .3s ease-in-out;

	&:after{
		content: '';
		position: absolute;
		height: 3px;
		left: 0;
		bottom: -10px;
		width: 0%;
		background: $third-color-darker;
		z-index: 9999;
		transition: all .3s ease-in-out;

	}

    &:hover{
    	background: none;
    	color: $black;

    	&:after{
    		width: 100%;
    	}
    }
}

.headernav-wrap{
	position: fixed;
	top: 0;
	z-index: 999;
	background-color: $white;
	width: 100%;
	padding-top: 15px;
}