/**
 * Header
 **/

 .header-image{
    background-size: cover;
    background-position: center bottom; 	
    height: 450px;
	margin-bottom: 1.5rem;
	width: 100%;

	.quote{
		margin-top: 10rem;
	}
 }

 .header{
 	padding-top: 165px !important;
 }