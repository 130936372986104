/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$primary-color: #E3B8A8;
$secondary-color: #F1EAE0;
$third-color: #bdc9b8;
$third-color-darker: #A28C7E;
$third-color-darkest: #565657;
$fourth-color: #ddceb2;
$fifth-color: #ce836f;

$white: #FFFFFF;
$black: #000000;
$blue: #067085;

/**
 * Fonts
 **/

$primary-font: 'Jost', sans-serif;
$secondary-font: 'Prata', serif;
$tertiary-font: 'Noto Sans', sans-serif;