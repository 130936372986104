/**
 * Classes
 **/

 .block-p{
	padding-top: 4rem;
	padding-bottom: 4rem;
 }

 .block-m{
	margin-top: 4rem;
	margin-bottom: 4em;
 }

/* Colors */

.c-primary{
	background-color: $primary-color;
}

.c-secondary{
	background-color: $secondary-color;
}

.c-third{
	background-color: $third-color;
}

.c-fourth{
	background-color: $fourth-color;
}

.c-fifth{
	background-color: $fifth-color;
}

.blue{
	color: $blue;
}